import React, { useCallback, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Image from 'gatsby-image';

import {
  themeCssVar as globalTheme,
  UiEngineProvider,
  ThemeGlobalStyles,
  useThemeClassName,
} from '@tinderapp/ui-react';

// NOTE: Stylesheet imports are injected into ALL PAGES regardless of whether the layout uses them.
import '@tinderapp/ui-core/fonts/face.css';
import '@tinderapp/ui-core/themes/default.css';

import {
  centerContainerMaxWidth,
  minWidthMedium,
  minWidthSmall,
  maxWidthSmall,
} from '../lib/styles';
import { useMaxWidthSmall } from '../lib/hooks';
import { HEADER_HEIGHT_SMALL, HEADER_HEIGHT_LARGE, SPACING_VERTICAL } from '../lib/sizing';

import ThemeVariables from './ThemeVariables';
import GlobalStyle from './GlobalStyle';
import Footer from './Footer';
import Header from './Header';
import HeaderNav from './HeaderNav';
import Main from './Main';

const NativeImage = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
const FluidImage = styled(Image)``;

const HeaderNavWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEADER_HEIGHT_SMALL}px;
  background-color: ${globalTheme.color.backgroundPrimary};
  border-bottom: 1px solid ${globalTheme.color.borderSecondary};
  z-index: 100;

  ${Header} {
    height: 100%;
    ${centerContainerMaxWidth}
  }

  ${HeaderNav} {
    position: fixed;
    top: ${HEADER_HEIGHT_SMALL}px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;

    background-color: ${globalTheme.color.backgroundPrimary};

    transition: transform 250ms ease-out, visibility 250ms linear;

    &:not(.active) {
      transition-delay: 0, 200ms;
      transform: translateX(100%);
      visibility: hidden;
      pointer-events: none;
    }
  }

  @media ${minWidthSmall} {
    ${HeaderNav} {
      display: none;
    }
  }

  @media ${minWidthMedium} {
    height: ${HEADER_HEIGHT_LARGE}px;

    ${HeaderNav} {
      top: ${HEADER_HEIGHT_LARGE}px;
    }
  }
`;

const HeaderWrapper = styled.div`
  background-color: ${globalTheme.color.backgroundSecondary};

  ${NativeImage},
  ${FluidImage} {
    max-height: 100vh;
  }

  @media ${minWidthMedium} {
    ${NativeImage},
    ${FluidImage} {
      height: 400px;
    }
  }
`;

const MainWrapper = styled.div`
  flex-grow: 1;

  ${Main} {
    ${centerContainerMaxWidth}
  }
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const FooterWrapper = styled.div`
  position: relative;
  background-color: ${globalTheme.color.backgroundPrimary};
  border-top: 1px solid ${globalTheme.color.borderSecondary};
  z-index: 90;

  @media ${maxWidthSmall} {
    display: none;
  }

  ${Footer} {
    ${centerContainerMaxWidth}
    padding-bottom: ${SPACING_VERTICAL(4)}
  }
`;

const SafetyCenterWebLayout = ({ children, hero }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = useCallback(() => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  }, []);

  const isSmallWindow = useMaxWidthSmall();

  const themeKey = 'light';

  // Ensure the body class for the theme is correct
  const themeClassName = useThemeClassName(themeKey);
  useLayoutEffect(() => {
    document.body.classList.add(themeClassName);
    return () => {
      document.body.classList.remove(themeClassName);
    };
  }, [themeClassName]);

  useLayoutEffect(() => {
    if (!isSmallWindow) {
      setMenuOpen(false);
    }
  }, [isSmallWindow]);

  useLayoutEffect(() => {
    document.body.classList.toggle('locked', isSmallWindow && menuOpen);
    return () => {
      document.body.classList.remove('locked');
    };
  }, [isSmallWindow, menuOpen]);
  return (
    <UiEngineProvider>
      <ThemeGlobalStyles themeVariablesComponent={ThemeVariables} />
      <GlobalStyle />
      <LayoutWrapper>
        <HeaderNavWrapper>
          <Header menuOpen={menuOpen} onMenuClick={isSmallWindow ? handleMenuClick : undefined} />
          <HeaderNav className={menuOpen ? 'active' : 'inactive'} />
        </HeaderNavWrapper>
        {hero ? (
          <HeaderWrapper>
            {typeof hero === 'string' ? (
              <NativeImage src={hero} alt="" />
            ) : (
              <FluidImage fluid={hero} />
            )}
          </HeaderWrapper>
        ) : null}
        <MainWrapper>
          <Main>{children}</Main>
        </MainWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </LayoutWrapper>
    </UiEngineProvider>
  );
};

SafetyCenterWebLayout.propTypes = {
  children: PropTypes.node.isRequired,
  hero: PropTypes.oneOfType([PropTypes.string, Image.propTypes.fluid]),
  locale: PropTypes.string,
  messages: PropTypes.shape({}),
};

SafetyCenterWebLayout.defaultProps = {
  hero: undefined,
  locale: undefined,
  messages: undefined,
};

export default SafetyCenterWebLayout;
