import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { createThemeKeyCss, namespaceCssScope } from '@tinderapp/ui-core';

const ThemeVariablesGlobalStyle = createGlobalStyle`
  ${({ namespace, cssScope = namespaceCssScope(namespace), dsTheme }) =>
    createThemeKeyCss(dsTheme, cssScope, {
      getPatternId: (type, patternKey) =>
        `svg-pattern-${type}__${namespace || dsTheme.themeId}__gradient--${patternKey}`,
    })}
`;

const ThemeVariables = ({ namespace, cssScope, theme }) => (
  <ThemeVariablesGlobalStyle namespace={namespace} cssScope={cssScope} dsTheme={theme} />
);

export default ThemeVariables;
